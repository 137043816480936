// custom typefaces
import 'typeface-montserrat'
import 'typeface-merriweather'

// Speed Tip: Use Typefaces.js in Gatsby to Locally Host Fonts
// https://dev.to/stoutlabs/speed-tip-use-typefacesjs-in-gatsby-to-locally-host-fonts-6om
// https://github.com/KyleAMathews/typefaces
import 'typeface-orbitron'
import 'typeface-raleway'

// Prism.js
require("prismjs/themes/prism-okaidia.css")